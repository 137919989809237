import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RouteComponentProps } from "react-router-dom";
import { PageHeader, Button, Form, Row, Col, Input, Descriptions, Card, Divider, Tag, Table, Empty, Typography } from "antd";
import { ClearOutlined, LockOutlined, SaveOutlined } from "@ant-design/icons";
import { RootState } from "@app/reducers";
import { FormatDate, FormatNumber, Orders } from "@app/components";
import {
    getByIdUserAction,
    getByIdUserPositionsAction,
    getByIdUserHistoryAction,
    getByIdUserTradersAction,
    getByIdUserProtectionAction,
    resetPasswordByIdUserAction,
    clearAccountByIdUserAction
} from "@app/actions/user_action";

const mapStateToProps = (state: RootState) => ({
    user: state.userReducer.data,
    userPositions: state.userReducer.positions,
    userHistory: state.userReducer.history,
    userTrader: state.userReducer.trader,
    userProtection: state.userReducer.protection
})

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getByIdUserAction: getByIdUserAction,
            getByIdUserPositionsAction: getByIdUserPositionsAction,
            getByIdUserHistoryAction: getByIdUserHistoryAction,
            getByIdUserTradersAction: getByIdUserTradersAction,
            getByIdUserProtectionAction: getByIdUserProtectionAction,
            resetPasswordByIdUserAction: resetPasswordByIdUserAction,
            clearAccountByIdUserAction: clearAccountByIdUserAction
        },
        dispatch
    );

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<any, any, { userId: string }>;

const UserDetailsScreen: React.FC<Props> = (props) => {
    const [resetPassword, setResetPassword] = React.useState(false);
    const [clearAccount, setClearAccount] = React.useState(false);
    const [form] = Form.useForm();
    const { userId } = props.location.state;
    const {
        getByIdUserAction,
        getByIdUserPositionsAction,
        getByIdUserHistoryAction,
        getByIdUserTradersAction,
        getByIdUserProtectionAction,
        resetPasswordByIdUserAction,
        clearAccountByIdUserAction
    } = props;
    const { user, userPositions, userHistory, userTrader, userProtection } = props;

    const initLoading = React.useCallback(() => {
        getByIdUserAction(userId);
        getByIdUserPositionsAction(userId);
        getByIdUserHistoryAction(userId);
        getByIdUserTradersAction(userId);
        getByIdUserProtectionAction(userId);
    }, []);

    const onSave = (values: any) => {
        console.log(values)
    }

    const onResetPassword = async () => {
        setResetPassword(true);
        await resetPasswordByIdUserAction(userId);
        setResetPassword(false)
    }

    const onClearAccount = async () => {
        setClearAccount(true);
        await clearAccountByIdUserAction(userId);
        initLoading();
        setClearAccount(false)
    }

    const expandedRowRender = (e: any) => (
        <Table dataSource={e.symbols} pagination={false} scroll={{ y: 200 }}>
            <Table.Column title={"Ativos que o cliente não quer seguir"} render={(row) => row} />
        </Table>
    )

    const actionsButtons = [
        <Button loading={resetPassword} key="password" type="default" icon={<LockOutlined />} onClick={onResetPassword}>Enviar nova senha</Button>,
        <Button loading={clearAccount} key="clear" type="default" icon={<ClearOutlined />} onClick={onClearAccount}>Limpar conta do ssuário</Button>,
        <Button disabled={true} key="save" type="primary" icon={<SaveOutlined />} onClick={() => form.submit()}>Salvar</Button>
    ]

    React.useEffect(() => {
        initLoading();
    }, [initLoading, userId])

    React.useEffect(() => {
        if (user.data) {
            form.setFieldsValue(user.data);
        }
    }, [user, form])

    return (
        <PageHeader
            ghost={false}
            title={user.data?.email}
            extra={actionsButtons}
            onBack={() => props.history.goBack()}
        >
            <Row gutter={[24, 24]}>
                <Col xl={16} lg={24} style={{ display: "inline-flex", alignSelf: "stretch" }}>
                    <Card loading={user.isProcessing} title="Informações Gerais">
                        <Form form={form} layout={"vertical"} onFinish={onSave}>
                            <Row gutter={16}>
                                <Col lg={14} md={24}>
                                    <Form.Item hasFeedback name="name" label="Nome Completo"
                                               rules={[{ required: true, message: "Insira o nome completo" }]}>
                                        <Input autoComplete="off" placeholder="Nome Completo" />
                                    </Form.Item>
                                </Col>
                                <Col xl={4} lg={6} md={24}>
                                    <Form.Item name="phone" label="Telefone">
                                        <Input autoComplete="off" placeholder="Telefone" />
                                    </Form.Item>
                                </Col>
                                <Divider type={"horizontal"} orientation={"left"} />
                                <Col lg={10} md={24}>
                                    <Form.Item name={["address", "address"]} label="Endereço">
                                        <Input autoComplete="off" placeholder="Endereço" />
                                    </Form.Item>
                                </Col>
                                <Col lg={4} md={12} sm={24}>
                                    <Form.Item name={["address", "number"]} label="Número">
                                        <Input autoComplete="off" placeholder="Número" />
                                    </Form.Item>
                                </Col>
                                <Col lg={6} md={12} sm={24}>
                                    <Form.Item name={["address", "complement"]} label="Complemento">
                                        <Input autoComplete="off" placeholder="Complemento" />
                                    </Form.Item>
                                </Col>
                                <Col lg={4} md={12} sm={24}>
                                    <Form.Item name={["address", "zipCode"]} label="Zip Code">
                                        <Input autoComplete="off" placeholder="Zip Code" />
                                    </Form.Item>
                                </Col>
                                <Col lg={6} md={12} sm={24}>
                                    <Form.Item name={["address", "district"]} label="Bairro">
                                        <Input autoComplete="off" placeholder="Bairro" />
                                    </Form.Item>
                                </Col>

                                <Col lg={6} md={24}>
                                    <Form.Item name={["address", "country"]} label="País">
                                        <Input autoComplete="off" placeholder="País" />
                                    </Form.Item>
                                </Col>
                                <Col lg={6} md={24}>
                                    <Form.Item name={["address", "state"]} label="Estado">
                                        <Input autoComplete="off" placeholder="Estado" />
                                    </Form.Item>
                                </Col>
                                <Col lg={6} md={24}>
                                    <Form.Item name={["address", "city"]} label="Cidade">
                                        <Input autoComplete="off" placeholder="Cidade" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>

                <Col xl={8} lg={24} style={{ display: "inline-flex", alignSelf: "stretch" }}>
                    <Card loading={user.isProcessing} title="Informações da Conta" extra={(user.data?.connected ? <Tag color="green">CONECTADO</Tag> : <Tag color="red">DESCONECTADO</Tag>)}>
                        {user.data?.connected ?
                            <Descriptions column={{ xxl: 1, xl: 1, lg: 2, md: 1, sm: 1, xs: 1 }}>
                                <Descriptions.Item label="Corretora">{user.data?.account.broker}</Descriptions.Item>
                                <Descriptions.Item label="Conta">{user.data?.account.accountBroker}</Descriptions.Item>
                                <Descriptions.Item label="Tipo de Conta">{user.data?.account.accountType}</Descriptions.Item>
                                <Descriptions.Item label="Servidor">{user.data?.account.serverBroker}</Descriptions.Item>
                                <Descriptions.Item label="Saldo">
                                    <FormatNumber currency={user.data?.account.currency}>{user.data?.account.balance}</FormatNumber>
                                </Descriptions.Item>
                                <Descriptions.Item label="Capital Líquido">
                                    <FormatNumber currency={user.data?.account.currency}>{user.data?.account.equity}</FormatNumber>
                                </Descriptions.Item>
                                <Descriptions.Item label="Crédito">
                                    <FormatNumber currency={user.data?.account.currency}>{user.data?.account.credit}</FormatNumber>
                                </Descriptions.Item>
                                <Descriptions.Item label="Margem Livre">
                                    <FormatNumber currency={user.data?.account.currency}>{user.data?.account.freeMargin}</FormatNumber>
                                </Descriptions.Item>
                            </Descriptions>
                            :
                            <Empty style={{ alignSelf: "center" }} />
                        }
                    </Card>
                </Col>

                <Col lg={12} md={24} style={{ display: "inline-flex", alignSelf: "stretch" }}>
                    <Card loading={user.isProcessing} title="Informações de Licença" extra={(user.data?.license.plan?.id ? <Tag color="green">LICENCIADO</Tag> : <Tag color="red">SEM LICENÇA</Tag>)}>
                        {user.data?.license?.plan?.id ?
                            <Descriptions column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                                <Descriptions.Item span={2} label="Produto">{user.data?.license?.plan?.product?.name}</Descriptions.Item>
                                <Descriptions.Item label="Ativação">
                                    <FormatDate>{user.data?.license?.activationAt}</FormatDate>
                                </Descriptions.Item>
                                <Descriptions.Item label="Recorrência">
                                    <FormatDate>{user.data?.license?.expirationAt}</FormatDate>
                                </Descriptions.Item>
                            </Descriptions>
                            :
                            <Empty style={{ alignSelf: "center" }} />
                        }
                    </Card>
                </Col>

                {user.data?.connected && (
                    <>
                        <Col lg={12} md={24} style={{ display: "inline-flex", alignSelf: "stretch" }}>
                            <Card loading={userProtection.isProcessing} title="Gestão de Capital"
                                  extra={(userProtection.data && (userProtection.data.tp || userProtection.data.sl) ? <Tag color="green">ATIVADO</Tag> : <Tag color="red">DESATIVADO</Tag>)}>
                                {userProtection.data ?
                                    <Descriptions column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                                        <Descriptions.Item label="Take Profit">
                                            {userProtection.data.tp ?
                                                <Tag color={"green"}>{"ATIVO - "}<FormatNumber>{userProtection.data.tpValue}</FormatNumber> </Tag> :
                                                <Tag color={"red"}>DESATIVADO</Tag>
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Stop Loss">
                                            {userProtection.data.sl ?
                                                <Tag color={"green"}>{"ATIVO - "}<FormatNumber>{userProtection.data.slValue}</FormatNumber> </Tag> :
                                                <Tag color={"red"}>DESATIVADO</Tag>
                                            }
                                        </Descriptions.Item>
                                    </Descriptions>
                                    :
                                    <Empty style={{ alignSelf: "center" }} />
                                }
                            </Card>
                        </Col>

                        <Col lg={24} style={{ display: "inline-flex", alignSelf: "stretch" }}>
                            <Card title="Traders">
                                <Table size="small" loading={userTrader.isProcessing} dataSource={userTrader.list} pagination={false} rowKey={"trader"}
                                       expandable={{ expandedRowRender: expandedRowRender, rowExpandable: e => e.symbols && e.symbols.length > 0 ? true : false }}>
                                    <Table.Column title={"Trader"} dataIndex={["trader", "name"]} />
                                    <Table.Column title={"Status"}
                                                  render={(row) => {
                                                      if (row.closeOnly) return <Tag color={"orange"}>FECHAR APENAS</Tag>
                                                      else if (row.follow) return <Tag color={"green"}>SEGUINDO</Tag>
                                                      else return <Tag color={"red"}>NÃO SEGUE</Tag>
                                                  }} />
                                    <Table.Column title={"Gestão Automática de Lote"}
                                                  render={(row) => {
                                                      if (row.autoRisk == null) return null;
                                                      else if (row.autoRisk) return <Tag color="green">ATIVADO</Tag>;
                                                      else return <Tag color="orange">{"MANUAL " + row.value + "%"}</Tag>;
                                                  }} />
                                </Table>
                            </Card>
                        </Col>
                        <Col lg={24} style={{ display: "inline-flex", alignSelf: "stretch" }}>
                            <Card title="Posições abertas"
                                  extra={[
                                      <Typography.Text strong>
                                          Total de Posições: {userPositions.list.length}
                                      </Typography.Text>
                                  ]}
                            >
                                <Orders loading={userPositions.isProcessing} type={"position"} dataSource={userPositions.list} />
                            </Card>
                        </Col>
                        <Col lg={24}>
                            <Card title="Histórico"
                                  extra={[
                                      <Typography.Text strong type={userHistory.data?.profit < 0 ? "danger" : "success"}>
                                          Total de Lucro: <FormatNumber currency={user.data?.account.currency}>{userHistory.data?.profit}</FormatNumber>
                                      </Typography.Text>
                                  ]}
                            >
                                <Orders loading={userHistory.isProcessing} type={"history"} dataSource={userHistory.list} />
                            </Card>
                        </Col>
                    </>
                )}
            </Row>
        </PageHeader>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsScreen);
