import React from 'react';
import { connect } from 'react-redux';
import { Table, Button, PageHeader, Modal, Form, Input, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { RouteComponentProps } from 'react-router-dom';
import { RootState } from '@app/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { getAllUserAction } from '@app/actions/user_action';
import { sortTable, filterBasicTable, filterAdvancedTable } from '@app/utils';
import { appRender, connectedRender, enabledRender } from '@app/constants/list';
import axios from 'axios';

const mapStateToProps = (state: RootState) => ({
    user: state.userReducer.list,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getAllUserAction: getAllUserAction,
        },
        dispatch
    );

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps;

const UserListScreen: React.FC<Props> = (props) => {
    const { getAllUserAction, user, history } = props;
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [form] = Form.useForm();

    React.useEffect(() => {
        getAllUserAction();
    }, [getAllUserAction]);

    const onDetails = (id: string) => {
        history.push('/user/detail', { userId: id });
    };

    const onCreate = async (e: any) => {
        setLoading(true);
        try {
            await axios.post('/user', { name: e.name, email: e.email, phone: e.phone });
            notification.success({
                message: 'Usuário cadastrado com sucesso!',
            });
            getAllUserAction();
            setOpen(false);
        } catch {
            notification.error({
                message: 'Ocorreu um erro ao cadastrar o usuário, revise os dados e tente novamente.',
            });
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        form.resetFields();
    }, [open]);

    return (
        <PageHeader
            ghost={false}
            title="Usuários"
            extra={[
                <Button type="primary" onClick={() => setOpen(true)}>
                    Criar Usuário
                </Button>,
            ]}
        >
            <Table
                loading={user.isProcessing}
                size="small"
                dataSource={user.list}
                pagination={false}
                bordered
                rowKey="id"
                scroll={{ x: 1000 }}
            >
                <Table.Column title="Nome" dataIndex="name" {...sortTable('name')} {...filterAdvancedTable('name')} />
                <Table.Column
                    title="Email"
                    dataIndex="email"
                    {...sortTable('email')}
                    {...filterAdvancedTable('email')}
                />
                <Table.Column
                    width={100}
                    title="Conectado"
                    dataIndex="connected"
                    {...filterBasicTable('connected', connectedRender.list)}
                    render={connectedRender.render}
                />
                <Table.Column
                    width={50}
                    title="APP"
                    dataIndex="firstLogin"
                    {...filterBasicTable('firstLogin', appRender.list)}
                    render={appRender.render}
                />
                <Table.Column
                    width={80}
                    title="Status"
                    dataIndex="enabled"
                    {...filterBasicTable('enabled', appRender.list)}
                    render={enabledRender.render}
                />
                <Table.Column
                    width={100}
                    render={(row) => (
                        <Button type="link" icon={<EditOutlined />} onClick={() => onDetails(row.id)}>
                            Detalhes
                        </Button>
                    )}
                />
            </Table>
            <Modal
                title="Criar novo usuário"
                open={open}
                onCancel={() => setOpen(false)}
                okText={'Salvar'}
                onOk={form.submit}
                okButtonProps={{ loading: loading }}
            >
                <Form form={form} layout="vertical" onFinish={onCreate}>
                    <Form.Item label="Nome" name="name" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                        <Input placeholder={'Nome do Usuário'} />
                    </Form.Item>

                    <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                        <Input type="email" placeholder={'Email do Usuário'} />
                    </Form.Item>

                    <Form.Item label="Telefone" name="phone" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                        <Input placeholder={'Telefone do Usuário'} />
                    </Form.Item>
                </Form>
            </Modal>
        </PageHeader>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListScreen);
