import axios from "axios";
import { handlerError } from "@app/utils"
import { AppThunk } from "@app/actions/types";

export const getLoggedAction = (): AppThunk => async (dispatch) => {
    dispatch({ type: "GET_LOGGED" });
    setTimeout(async () => {
        const { data } = await axios.get("/user/current");
        dispatch({ type: "GET_LOGGED_SUCCESS", payload: data });
    }, 0)
}