import React from "react";
import { connect } from "react-redux";
import { Layout, Menu, Row, Col, Dropdown } from "antd";
import { DesktopOutlined, TeamOutlined, DownOutlined, LogoutOutlined, SolutionOutlined } from "@ant-design/icons";
import { Switch, Redirect, NavLink, Route, RouteComponentProps } from "react-router-dom"
import { PrivateRoute } from "@app/routes/MainRoutes";
import { Logo } from "@app/components";
import { RootState } from "@app/reducers";
import { bindActionCreators, Dispatch } from "redux";
import { getLoggedAction } from "@app/actions/logged_action";

import HomeScreen from "@app/views/home/HomeScreen";
import UserListScreen from "@app/views/user/UserListScreen";
import UserDetailsScreen from "@app/views/user/UserDetailsScreen";
import LoggedScreen from "@app/views/auth/LoggedScreen";
import AffiliationScreen from "@app/views/affiliation/AffiliationScreen";

const { Header, Sider, Content } = Layout;

const ROUTES = [
    {
        key: "/user",
        title: "Usuários",
        component: UserListScreen,
        icon: <TeamOutlined />,
        items: [{
            key: "/user/detail",
            component: UserDetailsScreen
        }],
        types: ["S"]
    },
]

const mapStateToProps = (state: RootState) => ({
    logged: state.loggedReducer
})

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getLoggedAction: getLoggedAction
        },
        dispatch
    );

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps;

const LoggedRoutes: React.FC<Props> = (props) => {
    const { getLoggedAction, logged, location } = props;

    React.useEffect(() => {
        getLoggedAction();
    }, [getLoggedAction])

    const DropDownProfile = () => (
        <Menu>
            <Menu.Item key="/logout" icon={<LogoutOutlined />}><NavLink to="/login">Logout</NavLink></Menu.Item>
        </Menu>
    );

    const selectedMenuChange = () => {
        const path = location.pathname;
        return ROUTES.filter((x: any) => {
            if (x.key === path) return true;
            else if (x.items) return x.items.filter((item: any) => item.key === path).length > 0;
            else return false;
        }).map(x => x.key)
    }

    const render = (props: RouteComponentProps, route: any) => {
        const url = props.match.url;
        if (route.items && route.items.length > 0) {
            return (
                [
                    <PrivateRoute key={route.key} path={`${url}/`} component={route.component} exact />,
                    ...route.items.map((x: any) => <PrivateRoute key={x.key} path={x.key} component={x.component} />)
                ]
            )
        }
        return (
            <PrivateRoute key={`${url}/`} path={`${url}/`} component={route.component} />
        )
    }

    if (!logged.data) {
        return (
            <LoggedScreen />
        )
    } else {
        return (
            <Layout>
                <Sider style={{ overflow: "auto", height: "100vh", position: "fixed", left: 0, top: 64 }}>
                    <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline" selectedKeys={selectedMenuChange()}>
                        {ROUTES.filter(x => x.types.indexOf(logged.data.type.code) != -1).map(x =>
                            <Menu.Item key={x.key} icon={x.icon}>
                                <NavLink key={x.key} to={x.key}>{x.title}</NavLink>
                            </Menu.Item>
                        )}
                    </Menu>
                </Sider>
                <Header style={{ position: "fixed", zIndex: 1000, width: "100%" }}>
                    <Row align={"middle"}>
                        <Col flex={"auto"}>
                            <Logo height={25} />
                        </Col>
                        <Col flex={"200px"} style={{ textAlign: "right" }}>
                            <Dropdown overlay={DropDownProfile} trigger={["click"]}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    {logged.data.email} <DownOutlined />
                                </a>
                            </Dropdown>
                        </Col>
                    </Row>
                </Header>
                <Layout style={{ marginLeft: 200, marginTop: 64 }}>
                    <Content style={{ margin: 20 }}>
                        <div style={{ padding: 10 }}>
                            <Switch>
                                {ROUTES.filter(x => x.types.indexOf(logged.data.type.code) != -1).map(x => (
                                    <Route
                                        key={x.key}
                                        path={x.key}
                                        render={(props: RouteComponentProps) => render(props, x)}
                                    />
                                ))}
                                <Redirect to={"/users"} from={"/"} />
                            </Switch>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoggedRoutes);
