import { notification } from "antd";

export const handlerError = (ex: any): string => {
    let error: string = "Ocorreu um erro, tente novamente";
    if (ex.response && ex.response.data.message) {
        error = ex.response.data.message;
    }
    notification.error({
        message: error
    })
    return error;
}

export * from "./table";