import axios from "axios";
import { handlerError } from "@app/utils"
import { AppThunk } from "@app/actions/types";
import { setAuthorization, removeAuthorization } from "@app/service/auth_service";

export const loginAction = (email: string, password: string): AppThunk => async dispatch => {
    let req = {
        email: email,
        password: password
    };
    try {
        dispatch({ type: "LOGIN" })
        const res = await axios.post("/login", req);
        const token = res.data.token;
        setAuthorization(token);
        dispatch({ type: "LOGIN_SUCCESS" })
    } catch (ex) {
        dispatch({ type: "LOGIN_FAIL", payload: handlerError(ex) })
    }
};

export const logoutAction = (): AppThunk => async dispatch => {
    dispatch({ type: "LOGOUT" })
    removeAuthorization();
};