const environments = {
    dev: {
        serverURL: "http://localhost:8080"
    },
    production: {
        serverURL: "https://api-e2mgtayu4a-ue.a.run.app"
    }
}

export default environments.production;
