import { ActionTypes, IProcessing } from "@app/reducers/types"

interface ILoginState extends IProcessing {
    isLogged: boolean,
    message: string
}

const LoginState: ILoginState = {
    isProcessing: false,
    isLogged: false,
    message: ""
}

const LoginReducer = (state = LoginState, action: ActionTypes): ILoginState => {
    switch (action.type) {
        case "LOGIN":
            return Object.assign({}, state, {
                isProcessing: true,
                isLogged: false,
                message: ""
            });
        case "LOGIN_SUCCESS":
            return Object.assign({}, state, {
                isProcessing: false,
                isLogged: true,
                message: ""
            });
        case "LOGIN_FAIL":
            return Object.assign({}, state, {
                isProcessing: false,
                isLogged: false,
                message: action.payload
            });
        default:
            return state;
    }
}

export default LoginReducer;