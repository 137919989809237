import React from "react";
import { ReactComponent as SVGLogo } from "@app/assets/img/logo.svg";

interface Props {
    height?: number,
    width?: number,
    style?: React.CSSProperties
}

const Logo: React.FC<Props> = (props) => {
    const { height, width, style } = props;
    return (
        <div style={{ ...style, ...{ display: "flex" } }}>
            <SVGLogo height={height} width={width} />
        </div>
    )
};

Logo.defaultProps = {
    height: 50,
    style: {}
};

export { Logo };