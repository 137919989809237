import { ActionTypes, IProcessing } from "@app/reducers/types"

interface IUserState extends IProcessing{
    data: any
}

const UserState: IUserState = {
    isProcessing: false,
    data: null
}

const LoggedReducer = (state = UserState, action: ActionTypes): IUserState => {
    switch (action.type) {
        case "GET_LOGGED":
            return Object.assign({}, state, {
                isProcessing: true,
                data: null
            });
        case "GET_LOGGED_SUCCESS":
            return Object.assign({}, state, {
                isProcessing: false,
                data: action.payload.user
            });
        case "GET_LOGGED_FAIL":
            return Object.assign({}, state, {
                isProcessing: false,
                data: null
            });
        default:
            return state;
    }
}

export default LoggedReducer;