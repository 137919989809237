import React from "react";
import ReactDOM from "react-dom";
//import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import configureStore from "@app/store";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider, notification } from "antd";
import ptBr from "antd/lib/locale/pt_BR";
import { MainRoutes } from "@app/routes/MainRoutes";
import HttpService from "@app/service/http_service";

import "@app/index.css";
import "@app/styles/less/antd.less";
import "@app/styles/scss/style.scss"

const store = configureStore();
//const history = createBrowserHistory();

HttpService.setupInterceptors(store);

notification.config({
    placement: "topRight",
    duration: 3
})

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={ptBr}>
            <MainRoutes />
        </ConfigProvider>
    </Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
