import React from "react";
import { Row, Col, Layout } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const LoggedScreen: React.FC = () => {

    return (
        <Layout style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
            <Layout.Content>
                <Row justify="center" align="middle">
                    <Col span={2}>
                        <LoadingOutlined style={{ fontSize: 70 }} />
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );
}

export default LoggedScreen;
