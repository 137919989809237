import React from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom"
import { isAuthorization } from "@app/service/auth_service"

import LoginScreen from "@app/views/auth/LoginScreen";
import LoggedRoutes from "@app/routes/LoggedRoutes";
import { notification } from "antd";

export const PrivateRoute: React.FC<any> = (props) => {
    const { component: Component, ...rest } = props;
    return (
        <Route
            {...rest}
            render={props =>
                isAuthorization() ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                )
            }
        />
    )
};

export const MainRoutes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path={"/login"} component={LoginScreen}></Route>
                <PrivateRoute path={"/"} component={LoggedRoutes} />
            </Switch>
        </BrowserRouter>
    );
}